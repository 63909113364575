import { signIn } from 'next-auth/react';
import styles from './index.module.scss';
import baseStyles from '@/styles/baseStyles.module.scss';
import signupStyles from '@/pages/signup/styles.module.scss';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import Text from '../components/DesignSystem/Text';
import Link from 'next/link';
import Head from 'next/head';
import useSignInWithEmail from '@/hooks/useSignInHelper';
import { getBedrockFavicon } from '@/utils/helpers';
import { SignupFormLayout } from '@/components/SignupFlow/SignupForm';
import useSessionStorage from '@/hooks/useSessionStorage';
import BedrockDiscoIcon from '@/icons/BedrockDiscoIcon';
import BedrockBaseAppIcon from '@/icons/BedrockBaseAppIcon';
import useAppWrapper from '@/hooks/useAppWrapper';

const getUserStatus = async (email: string): Promise<{ waitlist: boolean; complete: boolean; id: string } | null> => {
    return fetch('/api/getUserStatusByEmail?email=' + email).then((data) => data?.json());
};

const SignIn = ({ isBaseApp }) => {
    const router = useRouter();
    const signInWithEmail = useSignInWithEmail();
    const [userNotFoundEmail, setUserNotFoundEmail] = useState(null);
    const [unlinkedAccountError, setUnlinkedAccountError] = useState(null);
    const [signUpEmail, , refreshSignUpEmail] = useSessionStorage('signUpEmail');
    const [signUpCallbackUrl, , refreshSignUpCallbackUrl] = useSessionStorage('signUpCallbackUrl');
    const [emailSignIn, setEmailSignIn] = useState(false);
    const { isNotesApp } = useAppWrapper();

    useEffect(() => {
        setUnlinkedAccountError(null);
        if (router.query.error && router.query.error === 'OAuthAccountNotLinked') {
            setUnlinkedAccountError(true);
        }
    }, [router.query]);

    const handleEmailSubmit = async (email): Promise<void> => {
        setUserNotFoundEmail(null);
        setUnlinkedAccountError(null);
        const querySafeEmail = encodeURIComponent(email);
        const { id, waitlist } = await getUserStatus(querySafeEmail);
        if (id === undefined) {
            setUserNotFoundEmail(email);
            return;
        }

        if (waitlist) {
            router.push('/?waitlist=true');
            return;
        }

        const callbackUrl = `${window?.location.origin}/processRedirects?${isNotesApp ? 'callbackUrl=/notes' : ''}`;
        signInWithEmail({ email, callbackUrl, redirect: false });
        setEmailSignIn(true);
        refreshSignUpEmail();
        refreshSignUpCallbackUrl();
    };

    const handleGoogleSignIn = (): void => {
        signIn('google', { callbackUrl: `${window.location.origin}/processRedirects` });
    };

    const onCode = (code) => {
        const searchParams = new URLSearchParams();
        searchParams.set('callbackUrl', signUpCallbackUrl);
        searchParams.set('token', code);
        searchParams.set('email', signUpEmail);

        // for some reason just sending request and calling update
        // does not actually update session, so just reload page
        window.location.href = `/api/auth/callback/email?${searchParams.toString()}`;
    };

    const header = useMemo(() => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
                <div className={baseStyles.signupFormHeaderBase}>Log in to Bedrock</div>

                <div
                    style={{
                        display: userNotFoundEmail || unlinkedAccountError ? 'flex' : 'none',
                        flexDirection: 'column',
                        gap: 24,
                    }}
                >
                    {unlinkedAccountError && (
                        <div className={styles.errorContainer}>
                            <Text size="s" className={styles.notFoundText}>
                                You have an account with this email,
                                <br />
                                but it is not linked to a Google account.
                            </Text>
                        </div>
                    )}

                    {userNotFoundEmail?.length !== 0 && (
                        <div className={styles.errorContainer}>
                            <Text size="s" className={styles.notFoundText}>
                                <span>
                                    User with that email not found.
                                    <Link href="/signup">
                                        <a className="d-block">Click here to create account with {userNotFoundEmail}</a>
                                    </Link>
                                </span>
                            </Text>
                        </div>
                    )}
                </div>
            </div>
        );
    }, [userNotFoundEmail, unlinkedAccountError]);

    return (
        <>
            <Head>
                <title>Bedrock Login</title>
                <link rel="icon" type="image/x-icon" href={getBedrockFavicon('disco-favicon', 'ico')} />
                <link rel="apple-touch-icon" type="image/x-icon" href={getBedrockFavicon('disco-favicon', 'ico')} />
                <meta property="og:site_name" content="Bedrock" />
                <meta property="og:title" content="Bedrock - Login" />
                <meta property="og:description" content="Login into your Bedrock account" />
                <meta property="og:image" content="/images/socialMediaLinkImages/login.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content="Bedrock - Login" />
                <meta property="twitter:description" content="Login into your Bedrock account" />
                <meta property="twitter:image" content="/images/socialMediaLinkImages/login.png" />
            </Head>
            <div className={signupStyles.mainContainer}>
                {isBaseApp && (
                    <div className={baseStyles.logoContainer}>
                        <div className={baseStyles.logoDropShadow}>
                            <BedrockBaseAppIcon />
                        </div>
                        Base
                    </div>
                )}
                <div className={signupStyles.formContainer}>
                    <SignupFormLayout
                        logIn
                        emailSignIn={emailSignIn}
                        onCode={onCode}
                        onSubmit={handleEmailSubmit}
                        pending={false}
                        signInWithGoogle={handleGoogleSignIn}
                        header={header}
                    />
                </div>
                <div className={baseStyles.discoLogo}>
                    <BedrockDiscoIcon />
                </div>
            </div>
        </>
    );
};

export async function getServerSideProps(context) {
    const isBaseApp = context.req.headers['user-agent'].includes('BedrockBase');

    return {
        props: { isBaseApp },
    };
}

export default SignIn;
